import * as React from "react";
import { makeTile } from "../component-helpers";
import TileSection from "../components/common/tile-section";
import Search from "../components/search";
import { Recording } from "../types";

export interface RecentPageTemplateProps {
  recordings: Recording[];
}

const RecentPageTemplate: React.SFC<{
  pathContext: RecentPageTemplateProps;
}> = ({ pathContext }) => (
  <div className="papio-children">
    <Search />
    <TileSection title="Recent" tiles={pathContext.recordings.map(makeTile)} />
  </div>
);

export default RecentPageTemplate;
